<template>
  <div class="photo-detail">
    <titleBar
      :title="titleBar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <titleBar
      :img="bot?.avatar"
      :title="bot?.handle"
      :headerTitleLink="bot?.url"
      :back="false"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="true"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <!--  
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">{{ post.id }}</h1>
        </div>
      </div>
    </section>
-->

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-three-fifths">
            <postCard :item="post" :include-links="true" class="d" context="detail" />
            <!-- 
            aaaa
            <div class="images" v-if="post.images">
              <figure class="image" v-for="i in post.images" :key="i.url">
                <img :src="i.url" alt="Post Image" />
              </figure>
              source: {{ post.source }}
            </div> -->
          </div>
          <div class="column">
            <div class="box">
              <p>{{ post.text }}</p>

              <Comments
                entity="post"
                :id="post.id"
                :authorShortid="post.bot"
                :authorDisplayName="post.bot || post.bot"
                layout="card"
                v-if="post"
              />
            </div>
          </div>
        </div>
      </div>
      <json-debug :data="post" />
    </section>

    <section class="section" v-if="relatedPosts.length">
      <div class="container">
        <hr />
        <h2 class="title is-4">More like this</h2>
        <postList :items="relatedPosts" :pageSize="200" style="max-width: 550px" />
      </div>
    </section>
  </div>
</template>

<script>
//import { getBotPost } from "API";
import postCard from "@/components/PostCard.vue";
import postList from "@/components/PostList.vue";
import Comments from "@/components/e/comments.vue";

export default {
  components: {
    postCard,
    Comments,
    postList,
    // jsonDebug
  },
  data() {
    return {
      post: {},
      bot: {},
      relatedPosts: [],
    };
  },
  mounted() {
    this.loadPost();
  },
  metaInfo() {
    return {
      title: this.titleBar,
    };
  },
  computed: {
    postId() {
      return this.$route.params.postId;
    },
    titleBar() {
      if (this.isImage) return "Image";
      return "Post";
      return this.bot.name;
    },
    isImage() {
      if (!this.post.images) return false;
      return this.post.images && this.post.images.length > 0;
    },
    botId() {
      return this.$route.params.bot;
    },
  },
  watch: {
    $route(to) {
      this.loadPost(to.params.postId);
    },
  },
  methods: {
    async loadPost(postId) {
      this.post = {};
      this.bot = {};
      this.relatedPosts = [];
      const postIdToLoad = postId || this.$route.params.postId;
      window.API.getRelatedBotPosts(this.botId, postIdToLoad).then((r) => {
        this.relatedPosts = r;
      });
      window.API.getBotPost(this.botId, postIdToLoad)
        .then((p) => {
          this.post = p;
          //this.checkIfAllCallsAreCompleted();
          //window.prerenderReady = true; // TODO: move to a check if all requests have been done
          // console.log("PROJJ!", proj, proj.id);
        })
        .catch((e) => {
          console.error("Error loading post", e);
          this.post = {};
        });

      window.API.botProfile(this.botId)
        .then((b) => {
          this.bot = b;
          this.loadingBot = false;
          //this.checkIfAllCallsAreCompleted();
          //window.prerenderReady = true; // TODO: move to a check if all requests have been done
          // console.log("PROJJ!", proj, proj.id);
        })
        .catch((e) => {
          console.error("Error loading bot", e);
          this.loadingBot = false;
          this.bot = {};
        });
    },
  },
};
</script>
